export const prod = {
    BASE_URL_DOMAIN: "https://muisapi.givepls.com", // Common domain for all configuration, donor and payment APIs 
    BASE_URL : "https://edonation-prod-upgradedapi.givepls.com/v8/api/eDonation",
   //  BASE_URL_ENROLL: "https://s2uoehm5sd.execute-api.ap-southeast-1.amazonaws.com/Stage", // Enroll end point
   //  BASE_URL_CONFIG: "https://s7ml87jpfe.execute-api.ap-southeast-1.amazonaws.com/Stage",
   //  BASE_URL_OXPAY: "https://oazaz035k5.execute-api.ap-southeast-1.amazonaws.com/Stage",
    BASE_URL_POSTALCODE : "https://web-muiscrmapi.givepls.com", //postal code api base url
   //  PAYNOW_QR_URL : "https://test-payment.givepls.com", // paynow url for QR code generation // UAT URL
    NGS_SINGPASS_URL : "https://app.givepls.com",
    PAYNOW_QR_URL : "https://payment.givepls.com", // paynow url for QR code generation
    NGS_SINGPASS_ENV : 'PROD',
    CLIENT_PARAM : 'muis',
    SINGPASS_SEPERATOR : '_',
    SINGPASS_PLATFORM : 'web',

    INSURANCE_TYPE: {
      CONVENTIONAL: 1,
      TAKAFUL: 2
    },

    SINGPASS_APPS : {
       MYINFO : 'myinfo',
       SGVERIFY : 'sgverify'
    },

    FLOW : {
      MANUALLY : 'Manually',
      SINGPASS : 'Singpass',
      ANONYMOUS : 'Anonymous'
   },

   REGEX : {
      TITLECASE : /\b(\w)/g,
      NRIC: /^[STFGM]\d{7}[A-Z]$/,
      UEN: /^[A-Za-z0-9]*$/,
      UEN1: /((S|T|[0-9])([a-zA-Z0-9]{8,9})|([0-3])(\d{8})([A-Z]))/g,
      COMPANY_NAME: /^[a-zA-Z0-9\-&\s]*$/,
      CONTACT_PERSON: /^[a-zA-Z\s]*$/,
      EMAIL_ADDRESS: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      PASSWORD:/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{7,}$/,
      UNIT_NUMBER: /\d+[A-Za-z]*-/,
      UNIT_NUMBER_INPUT: /^[a-zA-Z0-9\-\s]*$/, // Input field validation
   },
   SAFARI_FORMAT: {
      DATE: 'D MMM YYYY',
      TIME: 'h:mm A'
   },
   
   SESSION_TIME: {
      NEW: 60,
      EXTENDED: 10
   },

   OXPAY_RESPONSE : {
      FAILED : 'failed',
      SUCCESS : 'succeeded',
      PENDING : 'pending',
      INITIATED : 'initiated',
   },

   PAYMENT_METHOD: {
      PAYNOW: 'PayNow',
      DEBITCARD: 'DebitCard'
   },

   LOGIN_DETAILS: {
      GOOGLE_CLIENT_ID: "431838566550-su7u1tfn7s35t4pib702pme7od3e5cbl.apps.googleusercontent.com",
      FACEBOOK_APP_ID: "761477355018270"
   },

   COUNTRY_CODE: {
      SINGAPORE : 'SG'
   },
   RECALL_DELAY: 30000,
   WORLDPAY_3DS_AMOUNT: 10000,
   DEFAULT_CARD_NAME: 'Card Holder Name',

   DATE_FORMAT : {
      NEXT_ZAKAT: 'DD MMM YYYY'
   },

   PAYMENT_TYPES: {
      CARD_PAYMENT: 1,
      GOOGLE_PAY: 2,
      APPLE_PAY: 3
   },
    CARD_TYPES : {
       VISA : 'VISA',
       MASTERCARD : 'Mastercard',
       AMEX : 'Amex',
       AMERICANEXPRESS : 'AMERICANEXPRESS',
       ECMC : 'ECMC'
    },
 
    DEFAULT_ORDER : 1,
    DONATION_AMOUNT : 1000000000000000,
    RATES: {
      RATE_ON_TOTAL: 0.025
   },

   PAYMENT_STATUS: {
      ACCEPTED: 'Accepted',
      COMPLETED: 'Completed',
      REFUNDED: 'Refunded',
      VOIDED: 'Voided',
      AMOUNT_ACCEPTED: 'amt accepted',
      AMOUNT_COMPLETED: 'amt accepted',
      AMOUNT_REFUNDED: 'amt refunded',
      AMOUNT_VOIDED: 'amt refunded',
   },
   
   //--------------------AUTH0 DETAILS ----------------------
    CONNECTION_TYPE : "Username-Password-Authentication",
   
   // -------------------- MUIS DETAILS -----------------------
    ILHAM_CHARITY_ID: 97,
    ILHAM_DONATION_TYPE: 23, 
    ENROLL_MANULLY_ID : 1,
    ENROLL_CORPORATE_ID : 2,
    APPEAL_ID_DEFAULT : 0,
   
   //----------------- HOMEPAGE BLOCK IDS ------------------- 
    NEWS_LETTER_BLOCK_ID : 13,
    GALLARY_EXPLORE_BLOCK_ID : 14,
    CONTACT_US_BLOCK_ID : 15,
    SOCIAL_MEDIA_BLOCK_ID : 16,
    DONATION_BLOCK_ID : 5,
    ADOPT_ART_WORK_BLOCK_ID : 6,
    CORE_PRIVILEDGE_BLOCK_ID : 9,
    PATRON_HEADING_BLOCK_ID : 8,
    PATRON_PROGRAM_BLOCK_ID : 10,
    DONOR_WALL_BLOCK_ID : 12,
    ACHIEVEMENTS_BLOCK_ID : 7,
    DONATION_WIDGET__BLOCK_ID : 4,
   
   //--------------------------------------------------
    DONOR_WALL_DONOTE_NOW_SETTING_ID : 16,
    DONOR_WALL_SEE_WALL_SETTING_ID : 15,
   
   //------------------------------------------------------
    DONATION_MIN_AMOUNT : 0.01,
    DONATION_MAX_AMOUNT : 999999,
   
    CONTACT_US : "#",
    SUPPORT : "#",
    SITE_MAP : "#",
    TERMS_OF_USE: "https://www.muis.gov.sg/Footer/Terms-of-Use",
    PRIVACY_POLICY: "https://www.muis.gov.sg/Footer/Privacy-Statement",
    TERMS_CONDITION : "https://www.ilham.sg/",
   
   //------------------------------------------------------
    TYPE : "PROD",

     AWS_COGNITO_LOGIN_POOL : {
        USER_POOL_ID: "ap-southeast-1_6GosiPtFW",
        CLIENT_ID: "62ltld1fbruvdd25pj62dfgmh6",   
     },

     // PAYMENT DONE THANK YOU VIDEO YOUTUBE URL
     PAYMENT_THANK_YOU_VIDEO: 'https://www.youtube.com/embed/Ln4VZEDGgc4?rel=1&modestbranding=0&autohide=1&mute=1&showinfo=0&controls=1&autoplay=1&playlist=Ln4VZEDGgc4&loop=1',

     // GOOGLE POSTAL CODE SERVICE
     GOOGLE_POSTAL_CODE_BASE_URL:'https://maps.googleapis.com/maps', //URL to get postal code

     //PAYNOW TIMER CONSTANTS
     QR_EXPIRE_DURATION : 5 * 60 * 1000, // 5 mins
     CHK_STATUS_INTERVAL : 3000, //3 sec
     ENQUIRY_GRACE_PERIOD : 30 * 1000, // 30 sec

     //ZAKAT BUSINESS CALCULATOR - MAXIMUM AMOUNT LIMIT ON FIELD
     MAX_AMT_LENGTH : 14, // $9,999,999,999
     
     // AWS AMPLIFY OAUTH DOMAIN
     OAUTH_DOMAIN: "muis-prod.auth.ap-southeast-1.amazoncognito.com",
 }

export const config = prod;

export const validateForm = {
   firstName: "Enter Valid First Name",
   fullName: "Enter full name",
   onbehalfOf: "Enter valid on behalf of name",
   address: "Please enter registered address",
   validMobile: "Please enter the valid mobile number",
   minMobileLength: "Minimum 8 numbers",
   maxMobileLength: "Maxmium 16 numbers",
   validEmail: "Please enter valid email address",
   requiredEmail: "Enter email address",
   validNric: "Enter Valid NRIC/FIN",
   validCkAgree: "Please Agree to our terms and conditions",
   taxDeductableText: "All contributions are entitled to a 250% tax deduction",
   validAgreeCheck: "Do you wish to receive information and updates on your Zakat contribution?",
   payMethod: "Please select any one option",
   nricExists: "Please Enter NRIC/FIN",
   dateOfBirth: "Please select date of birth",
   mobileNumberExists: "The mobile number already exists, please enter another mobile number",
}

export const sessionTimeUnit = {
   time: 30, 
   unit: "minutes"
};

// Not being used currently, maintaining constants from future perspective (UAT Config)
export const CARDINAL_COMMERCE = {
   GENERATE_SESSION_ID: "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect",
   GENERATE_CHALLENGE_URL: "https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp",
   PAYMENT_REQUEST_URL: "https://secure-test.worldpay.com/jsp/merchant/xml/paymentService.jsp"
};

export const APP_FLOW = {
   FIDYAH: "FIDYAH",
   ZAKAT: "ZAKAT"
}

export const TIME_ZONE = {
   SINGAPORE : "SGT",
   GREENWICH : "GMT",
}

export const appPreferenceId = {
   MUIS_FIDYAH: 14,
   MUIS_HARTA: 13,
   MUIS_FITRAH: 15,
}

export const paths = {
   WorldPayCSE: '/enc/common/wp/WPCSE',
   GPay3DSGD: '/enc/common/wp/GPKEY',
   GPayNo3DSDG: '/enc/common/wp/GP_NO3D_KEY',
   GPayRec3DSDG: '/enc/common/wp/GP_REC_KEY',
   StripeSecret: '/enc/common/STRIPE_PK',
   SessionKey: '/enc/muis/SECRET_KEY',
   ReceiptKey: '/enc/muis/RCPTKEY',
   CognitoLoginAccessKey: '/enc/muis/COGNITO_LOGIN_ACCESS_KEY',
   CognitoLoginClientId: '/enc/muis/COGNITO_LOGIN_CLIENT_ID',
   AuthorizerAccessKey: '/enc/common/AWS_USER_POOL_ID',
   AuthorizerClientId: '/enc/common/AWS_CLIENT_ID'
}

// Total Amount
export const MAX_OXPAY_ACCEPTANCE_AMOUNT = 150000;
export const GOOGLE_API_KEY_AUTOCOMPLETE='AIzaSyAtwvfTxowI9lOT0f1-q7j3xQIjK79KWTA';

export const minimumAmount = 0.01;

export const DATE_FORMAT_REGEX = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/
export const REACT_APP_CRYPTO_JS_PASSWORD = 'ApSt28622';
export const REDUX_ENCRYPTION_KEY = "€Koj;yu(Rok5)S&";
export const CRYPTO_ACCESS_KEY = "ptjXV9hR%EC&a!QK";
export const AWS_SECRET_ACCESS_KEY = "U2FsdGVkX19SSs7Wngm0SOJb1tOINsx/drLuRiOcWlRAR/cfD8M5qciOkZu4wIn+1Zx8YbSqN1CAkzD+LSvBdw==";
export const AWS_SECRET_KEY_ID = "U2FsdGVkX18sOS02jd4PiWKlw1k9k90Z5dMo05ODSHzoEhOEKk4Rsl21rODKTFrR";
export const AWS_REGION = "ap-southeast-1"