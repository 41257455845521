import { authHeader, handleResponse } from '../_helpers';
import { config } from "../../common/constant";

export const homePageService = {
    getHomePageConfig,
    getHomePageSettings,
    getDonationWidgetData,
    getDonorWallData,
    getPayPartners,
};

async function getHomePageConfig() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    const url = config.BASE_URL_DOMAIN + "/configuration-v8/api/Configuration/configureWebForCharity?charityId=" + config.ILHAM_CHARITY_ID;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                return false
            }
        }).catch(error => {
            return false;
        });
}

async function getHomePageSettings() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    const url = config.BASE_URL + "/v1/Home/homePageConfiguration?charityId=" + config.ILHAM_CHARITY_ID;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                return false
            }
        }).catch(error => {
            return false;
        });
}

async function getDonationWidgetData(appealId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    const url = config.BASE_URL + "/v1/Donation/donationWidget?charityId=" + config.ILHAM_CHARITY_ID + "&appealId=" + appealId;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {            
            return result.data;            
        }).catch(error => {
            return { status: 0 };
        });
}

async function getDonorWallData(pageNo, pageSize) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    const url = config.BASE_URL + "/v1/Donation/donorWall?charityId=" + config.ILHAM_CHARITY_ID + "&pageNumber=" + pageNo + "&pageSize=" + pageSize;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                return []
            }
        }).catch(error => {
            return [];
        });
}

async function getPayPartners(charityId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    // const url = BASE_URL + "/v1/Donation/getPayPartners?charityId=" + NGS_CHARITY_ID;
    const url = config.BASE_URL + "/v1/Donation/getPayPartners?charityId=" + charityId;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                return []
            }
        }).catch(error => {
            return [];
        });
}